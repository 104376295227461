<template>
  <div>
    <c-tab
      type="horizon"
      :dense="true"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :height="tabHeight"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="popupParam"
          :receivingSetHistory="receivingSetHistory"
          :attachInfo.sync="attachInfo"
          :imprTabInfo.sync="imprTabInfo"
          :isOld="isOld"
          :contentHeight.sync="contentHeight"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'fireFightingReceivingSetHistoryDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingReceivingSetHistoryId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'info',
      receivingSetHistory: {
        sopFireFightingReceivingSetHistoryId: '',  // 화재수신기 작동 이력 보고 일련번호
        plantCd: null,  // 사업장코드
        reportDate: '',  // 보고일
        locationDong: '',  // 장소_동
        locationFloor: '',  // 장소_층
        locationDetail: '',  // 세부위치
        sopFireFightingId: '',  // 소방설비 일련번호
        fireFightingName: '',  // 소방설비명
        sopFireFightingTypeName: '',  // 소방설비 종류명
        repeaterType: '',  // 중계기 번호
        repeater: '',  // 중계기
        repeaterMenufCompany: '',  // 제조사
        happened: '',  // 발생내용
        insufficiencies: '',  // 미흡사항
        causeAnalysis: '',  // 원인분석
        evacuationFlag: 'N',  // 대피유무
        evacuationCount: '',  // 대피인원
        evacuationDetails: '',  // 대피내용, 미대피 사유
        improvement: '',  // 개선방안
        remarks: '',  // 비고
        detectorOperatingPositionSrc: '', // 감지기 동작 위치
        maps: [],
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'FIRE_RECEIVING_SET',
        taskKey: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.sopFireFightingReceivingSetHistoryId)
    },
    tabHeight() {
      let height = this.contentHeight - 36;
      if (height < 800) {
        height = 800;
      }
      return String(height);
    },
    tabItems() {
      let _items = [
        { name: 'info', icon: 'info', label: 'LBLBASEINFO', component: () => import(`${'./fireFightingReceivingSetHistoryInfo.vue'}`) },
      ]
      if (this.isOld) {
        _items.push({
          name: 'improvement', icon: 'info', label: 'LBL0010138', component: () => import(`${'@/pages/common/ibm/imprTab.vue'}`)
        })
      }
      return _items;
    },
    imprTabInfo() {
      return {
        key: this.popupParam.sopFireFightingReceivingSetHistoryId, // 
        ibmTaskTypeCd: 'ITT0000210',
        ibmTaskUnderTypeCd: 'ITTU000240',
        requestContents: '(' + this.receivingSetHistory.reportDate + ') ' + this.receivingSetHistory.fireFightingName,
        disabled: false,
        isOld: true,
        isNotItemGrid: true,
        gridItem: {

        },
        gridOther: {
          title: '개선 목록',
        },
      }
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.fft.fireFightingReceivingSetHistory.get.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopFireFightingReceivingSetHistoryId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.receivingSetHistory, _result.data)
          this.$set(this.attachInfo, 'taskKey', this.popupParam.sopFireFightingReceivingSetHistoryId)
        },);
      } else {
        // nothing
      }
    },
    closePopup() {
      this.$emit('closePopup')
    }
  }
};
</script>